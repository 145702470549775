<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"> </Heading>
    <small>
      View users
    </small>
    <hr />
    <el-form ref="form" :model="form" @submit.native.prevent>
      <el-form-item label="Role" v-if="false">
        <el-radio-group v-model="role" @change="selectRole">
          <el-radio-button :label="null">All</el-radio-button>
          <el-radio-button label="Teacher">Teacher</el-radio-button>
          <el-radio-button label="Admin">Admin</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="Email / Name"
          v-model="studentQuery"
          class="input-with-select"
          @keyup.enter.native="searchUsers"
        >
          <el-button slot="append" icon="el-icon-search" @click="searchUsers">
            <span v-show="!isPhone">Search</span>
          </el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="text-center mt-3 mb-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="isPhone ? 6 : 10"
        :small="isPhone"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-table
      :size="isPhone ? 'mini' : ''"
      class="mt-4"
      :data="students"
      style="min-width: 100%"
      border
    >
      <el-table-column label="Name" min-width="140">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'AdminViewUser',
              params: {
                id: scope.row.id
              }
            }"
          >
            <b>
              {{ user.displayName(scope.row.first_name, scope.row.last_name) }}
            </b>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Role" min-width="100">
        <template slot-scope="scope">
          <span v-for="(role, index) in scope.row.all_roles" :key="index">
            <el-tooltip
              class="item"
              effect="dark"
              content="Admin"
              placement="top"
            >
              <el-tag v-if="role === 'super-admin'" type="success" class="mr-2">
                <i class="fas fas fa-building"></i>
              </el-tag>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="Teacher"
              placement="top"
            >
              <el-tag v-if="role === 'teacher'" type="success" class="mr-2">
                <i class="fas fa-chalkboard-teacher"></i>
              </el-tag>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="Pro"
              placement="top"
            >
              <el-tag v-if="role === 'pro'" type="warning" class="mr-2">
                <i class="fas fa-crown" />
              </el-tag>
            </el-tooltip>
          </span>
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="Student"
            placement="top"
          >
            <el-tag type="success" class="mr-2">
              <i class="fas fa-user"></i>
            </el-tag>
          </el-tooltip> -->
          <!-- <el-button type="text" @click="selectRole(scope.row)">
            <i class="fa fa-edit"></i>
          </el-button> -->
        </template>
      </el-table-column>
      <el-table-column label="Company" min-width="200">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.company">
            <router-link
              :to="{
                name: 'AdminCompanyInfo',
                params: { id: scope.row.company.id },
                query: { active: 'Users' }
              }"
            >
              <b>
                <i class="fas fa-building"></i>
                {{ scope.row.company.name }}
              </b>
            </router-link>
          </el-tag>
          <b v-else>
            SATMocks
          </b>
        </template>
      </el-table-column>
      <el-table-column prop="email" label="Email" min-width="200">
        <template slot-scope="scope">
          <template v-if="scope.row.company">
            <b>{{ scope.row.email }}</b>
          </template>
          <template v-else>
            <b v-if="scope.row.email_verified_at" class="text-success">
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  `${instant.formatGoogleTime(
                    scope.row.email_verified_at
                  )} verified`
                "
                placement="top"
              >
                <span>
                  <i class="el-icon-success"></i>
                  {{ scope.row.email }}
                </span>
              </el-tooltip>
            </b>
            <b v-else class="text-warning">
              <el-tooltip
                class="item"
                effect="dark"
                content="Pending"
                placement="top"
              >
                <span>
                  <i class="el-icon-warning"></i>
                  {{ scope.row.email }}
                </span>
              </el-tooltip>
            </b>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Balance" width="140">
        <template slot-scope="scope">
          <h5 class="text-success">
            {{ scope.row.coin }}
            <small v-if="scope.row.coin > 1">Coins</small>
            <small v-else-if="scope.row.coin > 0 && scope.row.coin < 2">
              Coin
            </small>
            <el-button
              class="ml-2"
              type="text"
              @click="selectStudent(scope.row)"
            >
              <i class="fa fa-edit"></i>
            </el-button>
          </h5>
        </template>
      </el-table-column>
      <el-table-column label="Plan" width="80">
        <template slot-scope="scope">
          <b
            v-if="(scope.row.all_roles || []).includes('pro')"
            style="color: orange;"
          >
            <el-popover placement="top" trigger="hover">
              <div class="text-center">
                <el-button
                  type="danger"
                  size="mini"
                  @click="assignRemovePro(scope.row.id)"
                >
                  Remove Pro
                  <i class="fas fa-crown" />
                </el-button>
              </div>
              <span class="proIcon" slot="reference">
                <i class="fas fa-crown" />
                Pro
              </span>
            </el-popover>
          </b>
          <b v-else>
            <el-tooltip
              class="item"
              effect="dark"
              content="Click to Upgrade 12 MONTHS Pro"
              placement="top"
            >
              <el-button
                type="info"
                @click="assignYearlyPro(scope.row.id)"
                size="small"
              >
                Free
              </el-button>
            </el-tooltip>
          </b>
        </template>
      </el-table-column>
      <el-table-column label="Pro 使用期限" width="130px">
        <template slot-scope="scope">
          <span v-if="scope.row.pro_expired_at">
            {{ moment(scope.row.pro_expired_at).format("YYYY-MM-DD") }}
          </span>
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                updateProDialogVisible = true;
                currentUser = scope.row;
              }
            "
          >
            <i class="fas fa-edit" />
          </el-button>
          <el-button
            v-if="scope.row.taken_unlimited_expire_at"
            type="danger"
            size="small"
            @click="() => removePro(scope.row.id)"
          >
            <i class="fas fa-user-minus" />
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Teacher" width="80">
        <template slot-scope="scope">
          <b
            v-if="(scope.row.all_roles || []).includes('teacher')"
            @click="assignTeacher(scope.row.id)"
          >
            <el-switch :value="true"> </el-switch>
          </b>
          <b v-else @click="assignTeacher(scope.row.id)">
            <el-switch :value="false"> </el-switch>
          </b>
        </template>
      </el-table-column>
      <el-table-column label="Date Registered" min-width="80">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="IP" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.last_ip">
            <template v-if="scope.row.last_iso">
              ({{ scope.row.last_iso }})
            </template>
            {{ scope.row.last_ip }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Logs" width="70">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'AdminUsersLogs',
              query: {
                search: scope.row.email
              }
            }"
          >
            <el-button type="success" size="mini">
              <i class="fa fa-eye"></i>
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="isPhone ? 6 : 10"
        :small="isPhone"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="Edit Balance"
      v-if="student"
      :visible.sync="showStudent"
      :width="isPhone ? '95%' : '50%'"
    >
      <el-form label-width="100px">
        <el-form-item label="User">
          <b>
            {{ user.displayName(student.first_name, student.last_name) }} ({{
              student.email
            }})
          </b>
        </el-form-item>
        <el-form-item label="Balance">
          <b class="text-success">
            {{ form.oCoins }}
            <small v-if="form.oCoins > 1">Coins</small>
            <small v-else-if="form.oCoins > 0 && form.oCoins < 2">
              Coin
            </small>
          </b>
        </el-form-item>
        <el-form-item label="Type">
          <el-radio-group v-model="form.type">
            <el-radio-button :label="1">Deposit</el-radio-button>
            <el-radio-button :label="0">Deduction</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="COINS">
          <el-input-number
            v-model="form.coins"
            :min="0"
            :max="form.type === 1 ? 999 : form.oCoins"
            :step="10"
            style="width: 100%;"
          ></el-input-number>
        </el-form-item>
        <el-form-item>
          <div class="text-right">
            <el-button
              type="success"
              :disabled="!(student && form.coins > 0)"
              @click="giveCoins"
            >
              Save
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="Edit Role"
      v-if="student"
      :visible.sync="showRole"
      width="30%"
    >
      <el-form label-width="100px">
        <el-form-item label="User">
          <b>
            {{ user.displayName(student.first_name, student.last_name) }} ({{
              student.email
            }})
          </b>
        </el-form-item>
        <el-form-item label="Select Role">
          <el-checkbox-group v-model="form.all_roles">
            <!-- <el-checkbox label="super-admin" value="super-admin">
              <i class="fas fas fa-building"></i>
              Admin
            </el-checkbox> -->
            <el-checkbox label="teacher" value="teacher">
              <i class="fas fa-chalkboard-teacher"></i>
              Teacher
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <div class="text-right">
            <el-button type="success" @click="saveRoles">
              Save
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="設定 Pro 會員有效日期"
      :visible.sync="updateProDialogVisible"
      width="400px"
    >
      <el-date-picker
        style="width: 100%;"
        class="datePicker"
        type="date"
        v-model="newExpiredDate"
        :picker-options="pickerOptions"
      />
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            () => {
              updateProDialogVisible = false;
              currentUser = null;
              newExpiredDate = null;
            }
          "
        >
          {{ $t("alert.cancel") }}
        </el-button>
        <el-button type="primary" @click="updateProExpiredDate">
          {{ $t("alert.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat";
import userApi from "@/apis/user";
import { user, instant } from "@ivy-way/material";
import Company from "@/apis/company";
import moment from "moment";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      role: null,
      moment,
      studentQuery: null,
      form: {
        uid: null,
        oCoins: 0,
        coins: 0,
        type: 1,
        all_roles: []
      },
      students: null,
      student: null,
      showStudent: false,
      resultsTotal: null,
      showRole: false,
      updateProDialogVisible: false,
      currentUser: null,
      newExpiredDate: null,
      pageSize: 10
    };
  },
  computed: {
    myTitle() {
      return "Users";
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.search) {
      this.studentQuery = this.$route.query.search;
    }
    this.getStudents();
  },

  methods: {
    assignYearlyPro(id) {
      this.$confirm("Upgrade 12 MONTHS Pro?", "", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(async () => {
          await SAT.assignYearlyPro(id);
          this.$message({
            type: "success",
            message: "Success!"
          });
          this.getStudents();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled."
          });
        });
    },
    async updateProExpiredDate() {
      try {
        await SAT.assignYearlyPro(this.currentUser.id, {
          expired_at: moment(this.newExpiredDate).format("YYYY/MM/DD")
        });
        this.getStudents();
        this.updateProDialogVisible = false;
        this.currentUser = null;
        this.newExpiredDate = null;
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    assignRemovePro(id) {
      this.$confirm("Remove Pro?", "", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(async () => {
          await SAT.assignRemovePro(id);
          this.$message({
            type: "success",
            message: "Success!"
          });
          this.getStudents();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled."
          });
        });
    },
    async assignTeacher(id) {
      await SAT.assignTeacher(id);
      this.getStudents();
    },
    searchUsers() {
      this.routerPush({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          search: this.studentQuery !== "" ? this.studentQuery : null
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    async getStudents() {
      let query = this.studentQuery;
      this.loading = true;
      const students = await SAT.getStudents({
        keyword: query !== "" ? query : null,
        page: this.page
      });
      this.loading = false;
      this.students = students.users.data;
      this.resultsTotal = students.users.total;
      this.pageSize = students.users.per_page;
    },
    selectStudent(student) {
      this.student = null;
      this.student = student;
      this.showStudent = true;
      this.form.uid = student.id;
      this.form.oCoins = student.coin;
      this.form.all_roles = student.all_roles;
    },
    selectRole(student) {
      this.student = null;
      this.student = student;
      this.showRole = true;
      this.form.uid = student.id;
      this.form.oCoins = student.coin;
      if (student.all_roles && student.all_roles.length > 0) {
        let roles = [];
        student.all_roles.forEach(role => {
          if (role === "teacher" || role === "super-admin") {
            roles.push(role);
          }
        });
        this.form.all_roles = roles;
      } else {
        this.form.all_roles = [];
      }
    },
    async saveRoles() {
      if (this.form.all_roles.indexOf("teacher") > -1) {
        const res = await SAT.assignTeacher(this.form.uid);
        this.$message({
          message: "Success!",
          type: "success"
        });
      }
      // window.location.reload();
      this.getStudents();
      this.showRole = false;
      this.form = {
        uid: null,
        type: 1,
        coins: 0,
        oCoins: 0,
        all_roles: []
      };
    },
    async giveCoins() {
      if (this.form.type === 1) {
        const res = await SAT.giveCoins(this.form.uid, {
          coins: this.form.coins
        });
        this.$message({
          message: "Success!",
          type: "success"
        });
      } else {
        const res = await SAT.deductCoins(this.form.uid, {
          coins: this.form.coins
        });
        this.$message({
          message: "Success!",
          type: "success"
        });
      }
      this.getStudents();
      this.showStudent = false;
      this.form = {
        uid: null,
        type: 1,
        coins: 0,
        oCoins: 0,
        all_roles: []
      };
    }
  }
};
</script>

<style scoped></style>
